import request from '@/utils/request'

// 逝者信息
export function findServiceOrderDeath(params) {
  return request({
    url: `/service_order_death`,
    method: 'get',
    params: params
  })
}
